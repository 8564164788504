@import 'animate.css';
@import 'ui.less';


@font-face {
    font-family: Poppins-Black;
    src: url('./assets/fonts/Poppins-Black.ttf');
  }
  
  @font-face {
    font-family: Poppins-Regular;
    src: url('./assets/fonts/Poppins-Regular.ttf');
  }
  
  @font-face {
    font-family: Poppins-Bold;
    src: url('./assets/fonts/Poppins-Bold.ttf');
  }
  
  @font-face {
    font-family: Poppins-Light;
    src: url('./assets/fonts/Poppins-Light.ttf');
  }
  
  @font-face {
    font-family: Poppins-Thin;
    src: url('./assets/fonts/Poppins-Thin.ttf');
  }
  
  @font-face {
    font-family: Poppins-Medium;
    src: url('./assets/fonts/Poppins-Medium.ttf');
  }
  
  @font-face {
    font-family: Poppins-SemiBold;
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
  }
  
  html,
  body {
    min-height: 100%;
    font-family: Poppins-Regular;
  }
  
  button[nz-button] {
    font-size: 12px;
    font-family: Poppins-Medium;
  }
  
  .btn-large {
    padding-inline: 35px !important;
  }
  
  .btn-medium {
    padding-inline: 20px !important;
  }
  
  .subtitles-pages {
    font: normal normal bold 18px Poppins-Bold;
    text-align: left;
  }
  
  .my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  
  .my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  
  .mb-10 {
    margin-bottom: 10px;
  }
  
  .text-align-end {
    text-align: end;
  }
  
  .fa-margin{
    margin-inline: 5px;
  }
  
  .btn-icon{
    display: none !important;
  }
  
  .btn-label {
    display: inline-block !important;
  }
  
  .arrow-left-margin{
    margin-right: 15px;
  }
  
  .ant-card-head-title{
    font: normal normal 600 14px/20px Poppins-SemiBold;
  }
  
  .ant-form-item-label > label{
    font: normal normal normal 13px Poppins-Regular !important;
  }
  
  input[nz-input]{
    border-radius: 5px;
    font-size: 13px;
  }
  
  .ant-btn{
    line-height: 1.07 !important;
    white-space: normal !important;
  }
  
  //Breakpoint Md (768px)
  @media (max-width: 767px) {
    .btn-label {
      display: none !important;
    }
  
    .btn-icon{
      display: inline-block !important;
    }
  }