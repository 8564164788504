/**  Custom buttons properties  */

.btn-yellow-default,
.btn-yellow-default:focus,
.btn-yellow-default:active,
.btn-yellow-default:hover {
  background-color: #ffffff !important;
  color: @primary-yellow !important;
  border-radius: 5px !important;
  border-color: @primary-yellow !important;
}

.btn-gray-default,
.btn-gray-default:focus,
.btn-gray-default:active,
.btn-gray-default:hover {
  background-color: #ffffff !important;
  color: #a9a9a9 !important;
  border-radius:5px !important;
  border-color: #B6B6B6 !important;
}